var exports = {};

exports = function (hljs) {
  var LITERALS = {
    literal: "true false null"
  };
  var TYPES = [hljs.QUOTE_STRING_MODE, hljs.C_NUMBER_MODE];
  var VALUE_CONTAINER = {
    end: ",",
    endsWithParent: true,
    excludeEnd: true,
    contains: TYPES,
    keywords: LITERALS
  };
  var OBJECT = {
    begin: "{",
    end: "}",
    contains: [{
      className: "attr",
      begin: /"/,
      end: /"/,
      contains: [hljs.BACKSLASH_ESCAPE],
      illegal: "\\n"
    }, hljs.inherit(VALUE_CONTAINER, {
      begin: /:/
    })],
    illegal: "\\S"
  };
  var ARRAY = {
    begin: "\\[",
    end: "\\]",
    contains: [hljs.inherit(VALUE_CONTAINER)],
    // inherit is a workaround for a bug that makes shared modes with endsWithParent compile only the ending of one of the parents
    illegal: "\\S"
  };
  TYPES.splice(TYPES.length, 0, OBJECT, ARRAY);
  return {
    contains: TYPES,
    keywords: LITERALS,
    illegal: "\\S"
  };
};

export default exports;